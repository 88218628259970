.@{class-prefix}-home-btn {
    max-width     : 500px;
    height        : auto !important;
    text-transform: uppercase;
    border        : 1px solid;
    padding       : 8px 16px !important;
    font-size     : 18px !important;

    &.@{class-prefix}-home-btn-lg {
        padding  : 10px 16px !important;
        font-size: 21px !important;
    }
}

.number {
    font-family: Nunito, sans-serif !important;
    font-style : italic;
    font-size  : 48pt;
}