.list-flag {
    float: left;
    position: relative;
    height: 10px;
    width: 14px;
    display: block;
    margin-right: 6px;
    top: 3px;
    background-image: url('./flag-sprite.png');
}

.ant-select-selection-selected-value {
    width: 100%;
}

.ant-select-selection-item .list-flag {
    top: 11px;
}

.ant-select-item-option-content .list-flag {
    top: 5px;
}

.flag-ad {
    background-position: 0px 0px;
}

.flag-ae {
    background-position: 0px -13px;
}

.flag-af {
    background-position: 0px -26px;
}

.flag-ag {
    background-position: 0px -39px;
}

.flag-ai {
    background-position: 0px -52px;
}

.flag-al {
    background-position: 0px -65px;
}

.flag-am {
    background-position: 0px -78px;
}

.flag-an {
    background-position: 0px -91px;
}

.flag-ao {
    background-position: 0px -104px;
}

.flag-aq {
    background-position: 0px -117px;
}

.flag-ar {
    background-position: 0px -130px;
}

.flag-as {
    background-position: 0px -143px;
}

.flag-at {
    background-position: 0px -156px;
}

.flag-au {
    background-position: 0px -169px;
}

.flag-aw {
    background-position: 0px -182px;
}

.flag-ax {
    background-position: 0px -195px;
}

.flag-az {
    background-position: 0px -208px;
}

.flag-ba {
    background-position: 0px -221px;
}

.flag-bb {
    background-position: 0px -234px;
}

.flag-bd {
    background-position: 0px -247px;
}

.flag-be {
    background-position: 0px -260px;
}

.flag-bf {
    background-position: 0px -273px;
}

.flag-bg {
    background-position: 0px -286px;
}

.flag-bh {
    background-position: 0px -299px;
}

.flag-bi {
    background-position: 0px -312px;
}

.flag-bj {
    background-position: 0px -325px;
}

.flag-bl {
    background-position: 0px -338px;
}

.flag-bm {
    background-position: 0px -351px;
}

.flag-bn {
    background-position: 0px -364px;
}

.flag-bo {
    background-position: 0px -377px;
}

.flag-br {
    background-position: 0px -390px;
}

.flag-bs {
    background-position: 0px -403px;
}

.flag-bt {
    background-position: 0px -416px;
}

.flag-bw {
    background-position: 0px -429px;
}

.flag-by {
    background-position: 0px -442px;
}

.flag-bz {
    background-position: 0px -455px;
}

.flag-ca {
    background-position: 0px -468px;
}

.flag-cd {
    background-position: 0px -481px;
}

.flag-cf {
    background-position: 0px -494px;
}

.flag-cg {
    background-position: 0px -507px;
}

.flag-ch {
    background-position: 0px -520px;
}

.flag-ci {
    background-position: 0px -533px;
}

.flag-cl {
    background-position: 0px -546px;
}

.flag-cm {
    background-position: 0px -559px;
}

.flag-cn {
    background-position: 0px -572px;
}

.flag-co {
    background-position: 0px -585px;
}

.flag-cr {
    background-position: 0px -658px;
}

.flag-cu {
    background-position: 0px -611px;
}

.flag-cv {
    background-position: 0px -624px;
}

.flag-cy {
    background-position: 0px -637px;
}

.flag-cz {
    background-position: 0px -650px;
}

.flag-de {
    background-position: 0px -663px;
}

.flag-dj {
    background-position: 0px -676px;
}

.flag-dk {
    background-position: 0px -689px;
}

.flag-dm {
    background-position: 0px -702px;
}

.flag-do {
    background-position: 0px -715px;
}

.flag-dz {
    background-position: 0px -728px;
}

.flag-ec {
    background-position: 0px -741px;
}

.flag-ee {
    background-position: 0px -754px;
}

.flag-eg {
    background-position: 0px -767px;
}

.flag-eh {
    background-position: 0px -780px;
}

.flag-er {
    background-position: 0px -793px;
}

.flag-es {
    background-position: 0px -806px;
}

.flag-et {
    background-position: 0px -819px;
}

.flag-fi {
    background-position: 0px -832px;
}

.flag-fj {
    background-position: 0px -845px;
}

.flag-fk {
    background-position: 0px -858px;
}

.flag-fm {
    background-position: 0px -871px;
}

.flag-fo {
    background-position: 0px -884px;
}

.flag-fr {
    background-position: 0px -897px;
}

.flag-ga {
    background-position: 0px -910px;
}

.flag-gb,
.flag-uk,
.flag-en {
    background-position: 0px -923px;
}

.flag-gd {
    background-position: 0px -936px;
}

.flag-ge {
    background-position: 0px -949px;
}

.flag-gg {
    background-position: 0px -962px;
}

.flag-gh {
    background-position: 0px -975px;
}

.flag-gl {
    background-position: 0px -988px;
}

.flag-gm {
    background-position: 0px -1001px;
}

.flag-gn {
    background-position: 0px -1014px;
}

.flag-gq {
    background-position: 0px -1027px;
}

.flag-gr {
    background-position: 0px -1040px;
}

.flag-gs {
    background-position: 0px -1053px;
}

.flag-gt {
    background-position: 0px -1066px;
}

.flag-gu {
    background-position: 0px -1079px;
}

.flag-gw {
    background-position: 0px -1092px;
}

.flag-gy {
    background-position: 0px -1105px;
}

.flag-hk {
    background-position: 0px -1118px;
}

.flag-hn {
    background-position: 0px -1131px;
}

.flag-hr {
    background-position: 0px -1144px;
}

.flag-ht {
    background-position: 0px -1157px;
}

.flag-hu {
    background-position: 0px -1170px;
}

.flag-id {
    background-position: 0px -1183px;
}

.flag-ie {
    background-position: 0px -1196px;
}

.flag-il {
    background-position: 0px -1209px;
}

.flag-im {
    background-position: 0px -1222px;
}

.flag-in {
    background-position: 0px -1235px;
}

.flag-iq {
    background-position: 0px -1248px;
}

.flag-ir {
    background-position: 0px -1261px;
}

.flag-is {
    background-position: 0px -1274px;
}

.flag-it {
    background-position: 0px -1287px;
}

.flag-je {
    background-position: 0px -1300px;
}

.flag-jm {
    background-position: 0px -1313px;
}

.flag-jo {
    background-position: 0px -1326px;
}

.flag-jp {
    background-position: 0px -1339px;
}

.flag-ke {
    background-position: 0px -1352px;
}

.flag-kg {
    background-position: 0px -1365px;
}

.flag-kh {
    background-position: 0px -1378px;
}

.flag-ki {
    background-position: 0px -1391px;
}

.flag-km {
    background-position: 0px -1404px;
}

.flag-kn {
    background-position: 0px -1417px;
}

.flag-kp {
    background-position: 0px -1430px;
}

.flag-kr {
    background-position: 0px -1443px;
}

.flag-kw {
    background-position: 0px -1456px;
}

.flag-ky {
    background-position: 0px -1469px;
}

.flag-kz {
    background-position: 0px -1482px;
}

.flag-la {
    background-position: 0px -1495px;
}

.flag-lb {
    background-position: 0px -1508px;
}

.flag-lc {
    background-position: 0px -1521px;
}

.flag-li {
    background-position: 0px -1534px;
}

.flag-lk {
    background-position: 0px -1547px;
}

.flag-lr {
    background-position: 0px -1560px;
}

.flag-ls {
    background-position: 0px -1573px;
}

.flag-lt {
    background-position: 0px -1586px;
}

.flag-lu {
    background-position: 0px -1599px;
}

.flag-lv {
    background-position: 0px -1612px;
}

.flag-ly {
    background-position: 0px -1625px;
}

.flag-ma {
    background-position: 0px -1638px;
}

.flag-mc {
    background-position: 0px -1651px;
}

.flag-md {
    background-position: 0px -1664px;
}

.flag-me {
    background-position: 0px -1677px;
}

.flag-mg {
    background-position: 0px -1750px;
}

.flag-mh {
    background-position: 0px -1703px;
}

.flag-mk {
    background-position: 0px -1716px;
}

.flag-ml {
    background-position: 0px -1729px;
}

.flag-mm {
    background-position: 0px -1742px;
}

.flag-mn {
    background-position: 0px -1755px;
}

.flag-mo {
    background-position: 0px -1768px;
}

.flag-mp {
    background-position: 0px -1781px;
}

.flag-mr {
    background-position: 0px -1794px;
}

.flag-ms {
    background-position: 0px -1807px;
}

.flag-mt {
    background-position: 0px -1820px;
}

.flag-mu {
    background-position: 0px -1833px;
}

.flag-mv {
    background-position: 0px -1846px;
}

.flag-mw {
    background-position: 0px -1859px;
}

.flag-mx {
    background-position: 0px -1872px;
}

.flag-my {
    background-position: 0px -1885px;
}

.flag-mz {
    background-position: 0px -1898px;
}

.flag-na {
    background-position: 0px -1911px;
}

.flag-ne {
    background-position: 0px -1924px;
}

.flag-nf {
    background-position: 0px -1937px;
}

.flag-ng {
    background-position: 0px -1950px;
}

.flag-ni {
    background-position: 0px -1963px;
}

.flag-nl {
    background-position: 0px -1976px;
}

.flag-no {
    background-position: 0px -1989px;
}

.flag-np {
    background-position: 0px -2002px;
}

.flag-nr {
    background-position: 0px -2015px;
}

.flag-nz {
    background-position: 0px -2028px;
}

.flag-om {
    background-position: 0px -2041px;
}

.flag-pa {
    background-position: 0px -2054px;
}

.flag-pe {
    background-position: 0px -2067px;
}

.flag-pg {
    background-position: 0px -2080px;
}

.flag-ph {
    background-position: 0px -2093px;
}

.flag-pk {
    background-position: 0px -2106px;
}

.flag-pl {
    background-position: 0px -2119px;
}

.flag-pn {
    background-position: 0px -2132px;
}

.flag-pr {
    background-position: 0px -2145px;
}

.flag-ps {
    background-position: 0px -2158px;
}

.flag-pt {
    background-position: 0px -2171px;
}

.flag-pw {
    background-position: 0px -2184px;
}

.flag-py {
    background-position: 0px -2197px;
}

.flag-qa {
    background-position: 0px -2210px;
}

.flag-ro {
    background-position: 0px -2223px;
}

.flag-rs {
    background-position: 0px -2236px;
}

.flag-ru {
    background-position: 0px -2249px;
}

.flag-rw {
    background-position: 0px -2262px;
}

.flag-sa {
    background-position: 0px -2275px;
}

.flag-sb {
    background-position: 0px -2288px;
}

.flag-sc {
    background-position: 0px -2301px;
}

.flag-sd {
    background-position: 0px -2314px;
}

.flag-se {
    background-position: 0px -2327px;
}

.flag-sg {
    background-position: 0px -2340px;
}

.flag-sh {
    background-position: 0px -2353px;
}

.flag-si {
    background-position: 0px -2366px;
}

.flag-sk {
    background-position: 0px -2379px;
}

.flag-sl {
    background-position: 0px -2392px;
}

.flag-sm {
    background-position: 0px -2405px;
}

.flag-sn {
    background-position: 0px -2418px;
}

.flag-so {
    background-position: 0px -2431px;
}

.flag-sr {
    background-position: 0px -2444px;
}

.flag-st {
    background-position: 0px -2457px;
}

.flag-sv {
    background-position: 0px -2470px;
}

.flag-sy {
    background-position: 0px -2483px;
}

.flag-sz {
    background-position: 0px -2496px;
}

.flag-tc {
    background-position: 0px -2509px;
}

.flag-td {
    background-position: 0px -2522px;
}

.flag-tg {
    background-position: 0px -2535px;
}

.flag-th {
    background-position: 0px -2548px;
}

.flag-tj {
    background-position: 0px -2561px;
}

.flag-tl {
    background-position: 0px -2574px;
}

.flag-tm {
    background-position: 0px -2587px;
}

.flag-tn {
    background-position: 0px -2600px;
}

.flag-to {
    background-position: 0px -2613px;
}

.flag-tr {
    background-position: 0px -2626px;
}

.flag-tt {
    background-position: 0px -2639px;
}

.flag-tv {
    background-position: 0px -2652px;
}

.flag-tw {
    background-position: 0px -2665px;
}

.flag-tz {
    background-position: 0px -2678px;
}

.flag-ua {
    background-position: 0px -2691px;
}

.flag-ug {
    background-position: 0px -2704px;
}

.flag-us {
    background-position: 0px -2717px;
}

.flag-uy {
    background-position: 0px -2730px;
}

.flag-uz {
    background-position: 0px -2743px;
}

.flag-vc {
    background-position: 0px -2756px;
}

.flag-ve {
    background-position: 0px -2769px;
}

.flag-vg {
    background-position: 0px -2782px;
}

.flag-vi {
    background-position: 0px -2795px;
}

.flag-vn {
    background-position: 0px -2808px;
}

.flag-vu {
    background-position: 0px -2821px;
}

.flag-ws {
    background-position: 0px -2834px;
}

.flag-ye {
    background-position: 0px -2847px;
}

.flag-za {
    background-position: 0px -2860px;
}

.flag-zm {
    background-position: 0px -2873px;
}

.flag-zw {
    background-position: 0px -2886px;
}

.flag-we {
    background-position: 0px -2899px;
}

.flag-ct {
    background-position: 0px -2912px;
}
