.fixed-widgets {
    position      : fixed;
    right         : 32px;
    bottom        : 32px;
    z-index       : 2147483640;
    display       : flex;
    flex-direction: column;
    cursor        : pointer;
}

.fixed-widgets {
    .fixed-widgets-avatar {
        color           : #000;
        background-color: #fff;
        box-shadow      : 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        transition      : color .3s;
    }
}