.ant-descriptions {
    &.ks-descriptions {
        .ant-descriptions-row {
            &:nth-child(odd) {
                padding-bottom: 1rem;
            }
        }

        .ant-descriptions-item {
            border-left: 6px solid @grey-4;
            padding    : 0.2rem;

            .ant-descriptions-item-label {
                color      : @primary-color;
                font-weight: 600;
            }
        }
    }
}

.ks-card-descriptions {
    
    .ks-card-descriptions-title { 
        //background-color: @grey-3;       
        color: @text-color;
        width: 100%;
        font-size: @font-size-lg -2px;
        text-transform: uppercase;
    }

    .ks-descriptions-item {
        border-left  : 6px solid @grey-4;
        margin-bottom: 1rem;
        padding-left : 0.75rem;
        font-size    : 15px;

        .label {
            color      : @primary-color;
            font-weight: 600;
        }
        
        &.has-action {
            border-left: 6px solid @primary-color;
            background-color: @grey-3;
            padding: 1rem 0.75rem;
        }
    }
}

.ant-descriptions {
    &.gx-block-description {
        .ant-descriptions-item-container {
            display: block;
        }
    }
}